import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

function FacebookLogo(props: SvgIconProps) {
  return (
    <SvgIcon
      height="56"
      style={{ height: 'auto', width: 'auto' }}
      viewBox="0 0 56 56"
      width="56"
      {...props}
    >
      <path
        d="M55.1998 27.9999C55.1998 12.9778 43.022 0.799927 27.9998 0.799927C12.9776 0.799927 0.799805 12.9778 0.799805 27.9999C0.799805 41.576 10.7464 52.829 23.7498 54.8695V35.8624H16.8436V27.9999H23.7498V22.0074C23.7498 15.1904 27.8107 11.4249 34.0237 11.4249C36.9997 11.4249 40.1123 11.9562 40.1123 11.9562V18.6499H36.6826C33.3038 18.6499 32.2498 20.7468 32.2498 22.8978V27.9999H39.7936L38.5876 35.8624H32.2498V54.8695C45.2532 52.829 55.1998 41.5765 55.1998 27.9999Z"
        fill="#1877F2"
      />
      <path
        d="M38.5878 35.8624L39.7937 27.9999H32.25V22.8977C32.25 20.7467 33.304 18.6499 36.6827 18.6499H40.1125V11.9561C40.1125 11.9561 36.9999 11.4249 34.0238 11.4249C27.8109 11.4249 23.75 15.1904 23.75 22.0074V27.9999H16.8438V35.8624H23.75V54.8694C25.135 55.0867 26.5539 55.1999 28 55.1999C29.4461 55.1999 30.865 55.0867 32.25 54.8694V35.8624H38.5878Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export default FacebookLogo
