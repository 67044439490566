import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useIntl } from 'react-intl'

import { areExternalLinksVisible } from 'utils/areExternalLinksVisible'

import m from './messages'

function ContactSupport() {
  const { formatMessage: f } = useIntl()

  if (!areExternalLinksVisible) return null

  const supportUrl = 'https://www.samesystem.com/support/'

  return (
    <Typography align="center">
      {f(m.troubleLoggingIn)}
      &nbsp;
      <Button
        component="a"
        data-test-id="page.login.contactSupport"
        href={supportUrl}
        rel="noopener noreferrer"
        sx={{
          verticalAlign: 'baseline',
        }}
        target="_blank"
        variant="text"
      >
        {f(m.contactSupport)}
      </Button>
    </Typography>
  )
}

export default ContactSupport
