import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

function GoogleLogo(props: SvgIconProps) {
  return (
    <SvgIcon
      height="52"
      style={{ height: 'auto', width: 'auto' }}
      viewBox="0 0 52 52"
      width="52"
      {...props}
    >
      <path
        d="M51.1011 20.9863H49.039L25.999 20.8801V31.1201H40.4669C38.3561 37.081 32.6845 41.3601 25.999 41.3601C17.5165 41.3601 10.639 34.4826 10.639 26.0001C10.639 17.5175 17.5165 10.6401 25.999 10.6401C29.9145 10.6401 33.4768 12.1172 36.1891 14.53L43.4301 7.28905C38.8579 3.02793 32.7421 0.400085 25.999 0.400085C11.8614 0.400085 0.399027 11.8625 0.399027 26.0001C0.399027 40.1377 11.8614 51.6001 25.999 51.6001C40.1366 51.6001 51.599 40.1377 51.599 26.0001C51.599 24.2836 51.4224 22.6081 51.1011 20.9863Z"
        fill="#FBC016"
      />
      <path
        d="M3.35059 14.0846L11.7615 20.2529C14.0373 14.6183 19.549 10.6401 25.9989 10.6401C29.9144 10.6401 33.4767 12.1172 36.189 14.53L43.4299 7.28905C38.8578 3.02793 32.7419 0.400085 25.9989 0.400085C16.1659 0.400085 7.63859 5.95145 3.35059 14.0846Z"
        fill="#EC5144"
      />
      <path
        d="M25.9988 51.6C32.6113 51.6 38.6196 49.0695 43.1623 44.9543L35.2391 38.2496C32.6689 40.1965 29.474 41.36 25.9988 41.36C19.3402 41.36 13.6865 37.1143 11.5566 31.1891L3.2084 37.6211C7.4452 45.9117 16.0494 51.6 25.9988 51.6Z"
        fill="#3BAB59"
      />
      <path
        d="M51.1011 20.9864H49.039L25.999 20.8802V31.1202H40.4669C39.4531 33.9835 37.6112 36.4527 35.2355 38.2511C35.2368 38.2498 35.2381 38.2498 35.2393 38.2485L43.1625 44.9531C42.6019 45.4626 51.599 38.8002 51.599 26.0002C51.599 24.2837 51.4224 22.6082 51.1011 20.9864Z"
        fill="#4A8AF4"
      />
    </SvgIcon>
  )
}

export default GoogleLogo
