import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

function GooglePlayLogo(props: SvgIconProps) {
  return (
    <SvgIcon
      height="53"
      style={{ height: 'auto', width: 'auto' }}
      viewBox="0 0 53 53"
      width="53"
      {...props}
    >
      <path
        d="M26.009 25.3605L5.98904 46.2905C6.63904 48.5005 8.71904 50.1905 11.189 50.1905C12.229 50.1905 13.139 49.9305 13.919 49.4105L36.539 36.5405L26.009 25.3605Z"
        fill="#EA4335"
      />
      <path
        d="M46.2887 21.7204L36.5387 16.1304L25.6187 25.7504L36.6687 36.5404L46.4187 31.0804C48.1087 30.1704 49.2787 28.3504 49.2787 26.4004C49.1487 24.4504 47.9787 22.6304 46.2887 21.7204Z"
        fill="#FBBC04"
      />
      <path
        d="M5.98938 6.5105C5.85938 6.9005 5.85938 7.4205 5.85938 7.9405V44.9905C5.85938 45.5105 5.85938 45.9005 5.98938 46.4205L26.6292 25.9401L5.98938 6.5105Z"
        fill="#4285F4"
      />
      <path
        d="M26.139 26.4005L36.539 16.1305L14.049 3.39047C13.269 2.87047 12.229 2.61047 11.189 2.61047C8.71904 2.61047 6.50904 4.30047 5.98904 6.51047L26.139 26.4005Z"
        fill="#34A853"
      />
    </SvgIcon>
  )
}

export default GooglePlayLogo
