import { defineMessages } from 'components/LanguageProvider'

const messages = {
  contactSupport: {
    defaultMessage: 'contact our support team.',
    id: 'page.login.contactSupport',
  },
  investInSSContent: {
    defaultMessage:
      'Our mission is to empower retail and foodservice businesses with scalable, regulatory compliant and data-driven workforce management. We would be happy if you wanted to share your opinion with others',
    id: 'page.login.investInSS.content',
  },
  investInSSTitle: {
    defaultMessage: 'Would you like to recommend SameSystem to others?',
    id: 'page.login.investInSS.title',
  },
  troubleLoggingIn: {
    defaultMessage: 'If you are having trouble logging in please',
    id: 'page.login.troubleLoggingIn',
  },
}

export default defineMessages(messages)
